<template>
  <div>
    <fieldset class="form__fieldset">
      <legend class="title-20">Выбор территории действия</legend>
      <div class="uneditable-field">
        <label class="uneditable-field__label label"
          >Вид описания территории действия</label
        >
        <span
          class="uneditable-field__value"
          v-if="geometrySource.geometrySourceInfo.type.id == 7"
        >
          По границам объекта, связанного с документом разрешение на
          строительство, на который распространяются изменения
        </span>
        <span class="uneditable-field__value" v-else>
          По границам объекта, связанного с документом
        </span>
      </div>
      <div class="uneditable-field">
        <label class="uneditable-field__label label"
          >Наименование объекта:</label
        >
        <span
          v-for="(vector, index) in vectors"
          :key="index"
          class="uneditable-field__value"
        >
          {{ vector.name }}
        </span>
      </div>
    </fieldset>
  </div>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import GeometryProxy from "../../../proxies/geometryProxy";
import {
  GeometrySourceInfoFromDocument,
  GeometrySourceInfoFromOriginDocument,
  GeometrySource,
} from "../../../models/geometrySource";

export default {
  props: {
    stateSnapshot: {
      type: Object,
      required: true,
    },
    files: Array,
    geometrySource: Object,
  },
  emits: ["update:geometrySource", "error"],
  setup(props, { emit }) {
    let vectors = ref([]);
    let type = ref();

    if (props.stateSnapshot.zu) {
      if (Array.isArray(props.stateSnapshot.zu)) {
        vectors = ref(props.stateSnapshot.zu);
      } else {
        vectors.value.push(props.stateSnapshot.zu);
      }

      type.value = "LandPlot";
    } else {
      if (props.stateSnapshot.settlement) {
        vectors.value.push(props.stateSnapshot.settlement);
        type.value = "Settlement";
      }

      if (props.stateSnapshot.artZuRef) {
        vectors = ref(props.stateSnapshot.artZuRef);
        type.value = "ArtificialLandPlot";
      }

      if (props.stateSnapshot.zouitRef) {
        vectors = ref(props.stateSnapshot.zouitRef);
        type.value = "Zouit";
      }

      if (props.stateSnapshot.oopts) {
        vectors = ref(props.stateSnapshot.oopts);
        type.value = "Oopt";
      }

      if (props.stateSnapshot.forestryRef) {
        vectors = ref(props.stateSnapshot.forestryRef);
        type.value = "Forestry";
      }

      if (props.stateSnapshot.forestLandPlotRef) {
        vectors = ref(props.stateSnapshot.forestLandPlotRef);
        type.value = "ForestLandPlot";
      }
    }

    onBeforeMount(async () => {
      try {
        if (
          vectors.value &&
          !vectors.value.length &&
          props.geometrySource.geometry
        ) {
          if (props.geometrySource.geometry.original.geometry.includes("Line"))
          {
            emit(
            "update:geometrySource",
            new GeometrySource(
              new GeometrySourceInfoFromOriginDocument(),
              props.geometrySource.geometry)
            );

            emit("error", "Тип геометрии у объекта должен быть полигон");
            return;
          }

          if (props.geometrySource.geometrySourceInfo.type.id == 7)
            emit(
              "update:geometrySource",
              new GeometrySource(
                new GeometrySourceInfoFromOriginDocument(),
                props.geometrySource.geometry
              )
            );
          else
            emit(
              "update:geometrySource",
              new GeometrySource(
                new GeometrySourceInfoFromDocument(),
                props.geometrySource.geometry
              )
            );

          return;
        }
        const geometry = await GeometryProxy.getFromVector(
          type.value,
          vectors.value
        );
        if (geometry.original.geometry.includes("Line"))
          {
            emit(
            "update:geometrySource",
            new GeometrySource(
              new GeometrySourceInfoFromOriginDocument(),
              geometry)
            );

            emit("error", "Тип геометрии у объекта должен быть полигон");
            return;
          }

        if (props.geometrySource.geometrySourceInfo.type.id == 7)
          emit(
            "update:geometrySource",
            new GeometrySource(
              new GeometrySourceInfoFromOriginDocument(),
              geometry
            )
          );
        else
          emit(
            "update:geometrySource",
            new GeometrySource(new GeometrySourceInfoFromDocument(), geometry)
          );
      } catch (error) {
        if (error == "Cancel") return;
        emit(
          "update:geometrySource",
          new GeometrySource(new GeometrySourceInfoFromDocument(), null)
        );
        emit("error", "У объекта нет геометрии");
      }
    });

    return {
      vectors,
    };
  },
};
</script>

<style scoped lang="scss">
.form__fieldset {
  margin-bottom: 32px;
}
</style>
